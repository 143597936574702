.question-choice-field {
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 1.25rem;
  margin-bottom: 1.25rem;

  .question-type {
    background: #ffffff;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 9px 17px;
    width: 80px;
    height: 44px;
    margin-right: 20px;
    .question-index {
      font-weight: 600;
      font-size: 14px;
      line-height: 1.5rem;
      color: #111928;
      margin-right: 0.5rem;
      margin-top: 5px;
    }
  }

  .field-header > * {
    vertical-align: middle;
  }

  p {
    color: #aba5b8;
    font-size: 0.875rem;
    font-weight: bold;
  }

  .field-group {
    margin-bottom: 1rem;
    .checkboxes {
      display: flex;
      align-items: center;
      margin-left: 100px;
    }
  }

  .options {
    display: flex;
    align-items: center;
    width: 50%;
    .max {
      display: flex;
      align-items: center;

      span {
        color: #aba8b8;
        font-size: 0.875rem;
        margin-right: 0.5rem;
      }

      .icons-wrapper {
        display: flex;
        flex-direction: column;
      }

      .ant-input {
        width: 5rem;
        font-size: 0.875rem;
      }
    }
  }

  .questionnaire-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 40px;
  }

  .question-group {
    margin-top: 40px;
    .add-question {
      color: $primary-color;
      text-align: right;
    }
    .question-group-choice {
      margin-bottom: 40px;
      .question-group-choice-selector {
        width: 200px;
        margin-left: 20px;
        .ant-select-selector {
          border-radius: 16px;
        }
      }
    }
  }
}

.ant-dropdown-menu-item {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .choice-field {
    padding: 1rem;
  }
}
