$primary-color: #262626;
$red-color: #f05252;
$accent-color: yellow;
$sidebar-bg: #4f4f4f;
$color-gray: #828282;
$color-gray-1: #767676;
$color-gray-3: #959494;
$bg-background: #f8f9fb;
$green-color: #30d073;
$bg-thead: #f9fafb;
$color-gray1: #757575;
$color-black: #000;
$primary-transparant: rgba(0, 185, 252, 0.3);

$border-m: 0.25rem;
$border-l: 0.375rem;
$input-height: 2.8125rem;
$input-height-sm: 2.375rem;
$navbar-height: 60px;
$color-light-white: #f2f2f2;
$color-nav-light: #f7faff;
$icon-bg: #d8d8d8;
$text-muted: #757575;
$table-body: #373737;
$green-color2: #019d1a;
$green-color: #30d073;
$color-danger: #ff411f;
$color-orange: #fbb040;
$enable-negative-margins: true;
$input-border-color: #dbdbdb;

$border-color: #e5e7eb;
$text-color: #1f2a37;
$text-color-2: #111928;
$text-gray: #6b7280;
/* bootstrap theme color */
$theme-colors: (
  "primary": #00b9fc,
  "green": #30d073,
);
$color-input-border: #bdbdbd;
$border-color: #f2f2f2;

//dashboard colors
$unhealthy-color: #eb5757;
$unhealthy-color-faded: #f9cdcd;
$healthy-color: #30d073;
$healthy-color-faded: #d4f1e0;
$average-orange-color: #f2994a;
$average-orange-faded-color: #f6dcc6;
$unknown-range-color: #333333;
$unknown-range-color-faded: #e0e0e0;
$spacer: 1rem;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: (
    $spacer * 0.75,
  ),
  4: $spacer,
  5: (
    $spacer * 1.25,
  ),
  6: (
    $spacer * 1.5,
  ),
);
