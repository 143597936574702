.uar {
  // p {
  //   margin: 0;
  // }

  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  // &__header {
  //   width: 100%;
  //   padding: 1rem;
  //   @include flex(row, center, space-between);

  //   & > div {
  //     @include flex(row, center, space-between);

  //     & > p {
  //       font-size: 1.125rem;
  //       font-weight: 600;
  //     }

  //     & > input {
  //       width: 35vw;
  //       margin-left: 2vw;
  //       border-radius: 0.7rem;
  //     }
  //   }

  //   & > button {
  //     background-color: #262626;
  //     color: #fff;
  //     border-radius: 0.75rem;
  //     align-self: flex-end;
  //   }
  // }

  &__table-wrapper {
    flex: 1;
    min-height: 1px;
    overflow: auto;
    padding: 20px;

    &--style {
      .ant-table-thead > tr > th {
        background-color: #f3f4f6;
      }
    }
  }
}
