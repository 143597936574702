.econsent-participant-layout {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .econsent-participant-nav {
    height: 80px;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    padding: 0px 40px;
    .nav-wrapper {
      height: 100%;
      flex: 1;
      min-width: 1px;
      display: flex;
      justify-content: center;
      align-items: center;
      .nav-item {
        display: flex;
        text-decoration: none;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        margin-right: 2rem;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #484848;
        position: relative;
        &.active {
          color: #262626;
          &::after {
            width: 100%;
            content: " ";
            position: absolute;
            left: 0px;
            bottom: 0px;
            height: 3px;
            background-color: #262626;
            border-top-left-radius: 2px;
            border-bottom-right-radius: 2px;
          }
        }
      }
    }
    .nav-avatar {
      .anticon-user {
        vertical-align: 0;
      }
    }
  }
}
.econsent-participant-layout .dashboard {
  flex: 1;
  min-height: 1px;
  padding: 40px 15%;
  overflow: auto;
  display: flex;
  align-items: flex-start;
  .dashboard-card {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(28, 30, 33, 0.16);
    border-radius: 6px;
    padding: 30px;
  }
  .left-card {
    width: 50%;
    .welcome-header {
      font-weight: 600;
      font-size: 24px;
      line-height: 100%;
      color: #262626;
      margin-bottom: 20px;
    }
    .welcome-desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(33, 33, 33, 0.6);
      margin-bottom: 30px;
    }
    .welcome-tasks {
      .welcome-task-item {
        margin-bottom: 20px;
      }
      .welcome-task-item.done {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-decoration-line: line-through;
        color: #6b7280;
      }
      .welcome-task-item.incomplete {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-decoration-line: underline;
        color: #262626;
        .econsent-svg-path {
          stroke: #6b7280;
        }
      }
    }
    .call-header {
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #1f2a37;
      margin-bottom: 10px;
    }
    .call-desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(33, 33, 33, 0.6);
      margin-bottom: 20px;
    }
    .schedule-action {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
    }
    .scheduled-card {
      width: 100%;
      height: 71px;
      display: flex;
      align-items: center;
      padding: 6px;
      background: #ffffff;
      border: 1px solid #f2f2f2;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
    }
  }
  .right-card {
    width: 50%;
    .header {
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      color: #111928;
    }
    .desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #52525b;
      &.title,
      & .title {
        font-weight: 600;
        color: #000000;
      }
    }
  }
}
.econsent-participant-layout .documents {
  flex: 1;
  min-height: 1px;
  padding: 40px 15%;
  overflow: auto;
  .doc-card {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(28, 30, 33, 0.16);
    border-radius: 6px;
    padding: 40px;
    .header {
      font-weight: 600;
      font-size: 18px;
      line-height: 100%;
      color: #1f2a37;
      border-bottom: 1px solid #e5e7eb;
      padding-bottom: 20px;
    }
    .consent-item-wrapper {
      .consent-item {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e5e7eb;
        padding: 22px 0px;
        .title {
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: #212121;
        }
        .status,
        .read-only {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: rgba(33, 33, 33, 0.6);
        }
      }
    }
  }
}
.econsent-participant-layout .study-profile {
  flex: 1;
  min-height: 1px;
  padding: 40px 15%;
  overflow: auto;
  .profile-card {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(28, 30, 33, 0.16);
    border-radius: 6px;
    padding: 40px;
    .header {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #111928;
      margin-bottom: 10px;
    }
    .sub-header {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #52575c;
      margin-bottom: 40px;
    }
  }
}
