@mixin flex($dir, $align, $justify) {
  display: flex;
  flex-direction: $dir;
  align-items: $align;
  justify-content: $justify;
}

.sch {
  height: 100vh;
  width: 100%;
  background: #fafafa;
  @include flex(row, center, center);

  .back-header {
    position: fixed;
    top: 20px;
    left: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #a6a6a6;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__wrapper {
    background: #ffffff;
    border: 1px solid #d1d5db;
    border-radius: 12px;
    display: flex;
    width: 70%;
    min-width: 1000px;
    height: 80%;
    max-height: 700px;
  }
  &__panel {
    height: 100%;
    width: 40%;
    border-right: 1px solid #d1d5db;
    @include flex(column, none, none);

    &__brand {
      height: 30%;
      width: 100%;
      border-bottom: 1px solid #d1d5db;
      @include flex(column, center, center);

      & > img {
        height: 80%;
        width: 80%;
      }
    }

    &__details {
      height: 70%;
      width: 100%;
      padding: 1.4rem;

      &--title {
        font-weight: 700;
        color: #111928;
        margin: 0; //Remove Default Padding
        margin-top: 0.7rem;
      }

      &--subtitle {
        font-size: 0.75rem;
        color: #6b7280;
        font-weight: 500;
        margin: 0; //Remove Default Padding
      }

      &--duration {
        font-size: 1.5rem;
        font-weight: 700;
        margin: 0.7rem 0 1rem 0;
      }

      &--extra {
        height: 5vh;
        width: 100%;
        @include flex(row, center, none);

        & > p {
          margin: 0; //Remove Default Padding
          margin-left: 0.7rem;
          font-weight: 500;
          color: #6b7280;
        }
      }
    }
  }

  &__calendar {
    height: 100%;
    width: 60%;
    padding: 1.4rem;
    @include flex(column, flex-start, none);

    &__title {
      width: 100%;
      height: 10%;
      font-size: 1.25rem;
      font-weight: 700;
    }

    &__body {
      width: 100%;
      height: 90%;
      @include flex(row, none, none);

      &--cal {
        width: 60%;
        height: 100%;
        overflow: hidden;
        border-right: 1px solid #e5e7eb;

        // the calender wrapper
        // &__wrapper {}

        & > div {
          width: 100%;
          // margin-top: 7vh;
          @include flex(row, center, none);

          & > p {
            margin: 0;
            margin-left: 0.7rem;
            color: #6b7280;
            font-size: 0.75rem;
            font-weight: 600;
          }
        }
      }

      &--slots {
        width: 40%;
        height: 100%;
        margin-left: 20px;

        & > p {
          color: #6b7280;
          font-weight: 600;
          font-size: 1.125rem;
        }

        &--wrapper {
          overflow-y: auto;
          height: 90%;
          padding: 0.5rem;

          &__slot {
            height: 54px;
            width: 100%;
            border: 1px solid #1c64f2;
            margin-bottom: 1rem;
            border-radius: 4px;
            color: #1c64f2;
            @include flex(row, center, center);

            &:hover {
              border: 2px solid #1c64f2;
              cursor: pointer;
              background-color: #ebf5ff;
            }
          }

          &__slot-confirm {
            height: 54px;
            width: 100%;
            margin-bottom: 1rem;
            border-radius: 4px;
            @include flex(row, center, space-between);

            & > div {
              width: 48%;
              padding: 1rem;
              color: #fff;
              border-radius: 4px;
            }

            & > div:nth-child(1) {
              background-color: #6b7280;

              &:hover {
                cursor: pointer;
                background-color: #606673;
              }
            }

            & > div:nth-child(2) {
              background-color: #1c64f2;

              &:hover {
                cursor: pointer;
                background-color: #155ef1;
              }
            }
          }
        }
      }
    }
  }

  &__calendar-success {
    height: 100%;
    width: 60%;
    padding: 1.4rem;
    @include flex(column, center, center);

    & > img {
      height: 20rem;
      width: 20rem;
    }
  }
}

.scheduling-calendar {
  .ant-picker-content {
    thead {
      height: 55px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #1f2a37;
    }
  }
  .ant-picker-cell {
    .ant-picker-cell-inner {
      height: 40px;
      width: 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    .ant-picker-calendar-date {
      border-radius: 50%;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .ant-picker-cell-in-view:not(.ant-picker-cell-disabled) {
    .ant-picker-calendar-date {
      background: #ebf5ff;
      color: #1c64f2;
      border-radius: 50%;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }
  //Custom CSS for Selected Date
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    border-radius: 50%;
    background-color: #1c64f2;
    color: #fff;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border: none;
  }
}
