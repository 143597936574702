.auth-login {
  display: flex;
  height: 100vh;
  width: 100vw;
  position: relative;
  .logo {
    position: absolute;
    top: 60px;
    left: 60px;
  }
  .consent-auth-left-block {
    width: 50%;
    background-image: url("../../ConsentModules/assets/login.png");
    background-position: top;
    background-size: cover;
  }
  .consent-auth-right-block {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 4rem;
    .login-header {
      font-size: 43px;
      line-height: 64px;
      color: #212121;
      font-weight: 700;
      margin-bottom: 30px;
    }
    .terms {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #212121;
      .links {
        color: #1c64f2;
        font-size: 14px;
        cursor: pointer;
      }
    }
    .forgot-password {
      text-align: center;
      a {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        text-decoration: none;
        color: #1c64f2;
      }
    }
  }
}
.auth-forgot {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .back-header {
    position: fixed;
    top: 20px;
    left: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #a6a6a6;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .forgot-card {
    width: 35%;
  }
  .forgot-card.sent {
    min-height: 300px;
  }
  .forgot-header {
    font-weight: 600;
    font-size: 38px;
    line-height: 45px;
    color: #212121;
  }
  .forgot-sub-header {
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    color: #212121;
  }
  .resend {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #212121;
    .resend-link {
      color: #1c64f2;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
.econsent-landing {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  .landing-nav {
    padding: 0px 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  }
  .econsent-landing-content {
    flex: 1;
    min-height: 1px;
    background-image: url("../../ConsentModules/assets/Landing.png");
    background-position: top;
    background-size: cover;
    padding: 150px 15%;
    .econsent-landing-wrapper {
      width: 50%;
      .header {
        margin-top: 40px;
        margin-bottom: 30px;
        font-size: 43px;
        line-height: 64px;
        color: #212121;
        font-weight: 700;
      }
      .sub-header {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #3c3b3b;
        margin-bottom: 30px;
      }
      .desc {
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: #212121;
        margin-bottom: 30px;
      }
    }
  }
}
@media (max-width: 768px) {
  .auth-login {
    .logo {
      top: 20px;
      left: 20px;
    }
    .consent-auth-left-block {
      display: none;
    }
    .consent-auth-right-block {
      width: 100%;
      padding: 1rem;
    }
  }
  .auth-forgot {
    align-items: flex-start;
    .forgot-card {
      width: 100%;
      padding: 1rem;
    }
  }
}
