.consent-eligibility-layout {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ::selection {
    color: #fff;
    background: #1c64f2;
  }
  .eligible-stepper {
    height: 140px;
    padding: 24px 120px 24px 120px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    overflow: hidden;
    .step-item {
      padding: 0px 0.5rem;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      .step-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #6b7280;
        margin-bottom: 16px;
      }
      .dot {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #9ca3af;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    .step-item.active {
      .dot {
        background: #1c64f2;
      }
      .eligible-svg {
        .eligible-svg-path {
          fill: #111928;
        }
      }
      .step-title {
        color: #111928;
      }
    }
    .divider {
      flex: 1;
      min-width: 1px;
      height: 2px;
      background-color: #e5e7eb;
    }
    .divider.active {
      background: #1c64f2;
    }
  }
}
.eligibility-step-one {
  flex: 1;
  min-height: 1px;
  overflow: hidden;
  display: flex;
  .static-content {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    align-items: center;
    & > div {
      max-width: 50%;
    }
    .header {
      font-weight: 600;
      font-size: 24px;
      line-height: 150%;
      color: #18181b;
      margin-top: 50px;
      margin-bottom: 8px;
    }
    .timer {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #6b7280;
      margin-bottom: 16px;
    }
    .description {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #6b7280;
    }
  }
  .step-one-form {
    padding: 20px 60px;
    width: 50%;
    overflow: auto;
    .header {
      font-weight: 600;
      font-size: 24px;
      line-height: 150%;
      color: #18181b;
      margin-bottom: 50px;
    }
    .terms {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #6b7280;
      .link {
        color: #1c64f2;
      }
    }
  }
}
.info-form {
  flex: 1;
  min-height: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .info-form-wrapper {
    width: 50%;
    min-width: 500px;
    margin-top: 50px;
    .header {
      font-weight: 600;
      font-size: 24px;
      line-height: 150%;
      color: #18181b;
      margin-bottom: 50px;
    }
    .sub-header {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #111928;
      padding-bottom: 30px;
      margin-bottom: 20px;
    }
    .terms {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #6b7280;
      .link {
        color: #1c64f2;
      }
    }
    .description {
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      color: #494949;
      margin-bottom: 40px;
    }
  }
}
.step-one-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 1px;
  .step-one-success-wrapper {
    width: 40%;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .header {
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      text-align: center;
      color: #212121;
      margin-top: 40px;
      margin-bottom: 16px;
    }
    .sub-header {
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      color: #000000;
    }
  }
}
.step-two-failure {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .back-header {
    position: fixed;
    top: 20px;
    left: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #a6a6a6;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .header {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: #212121;
    margin-top: 40px;
    margin-bottom: 8px;
  }
  .sub-header {
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: #ff2020;
  }
}
.step-two-CRF {
  flex: 1;
  min-height: 1px;
  overflow: hidden;
  .step-two-CRF-wrapper {
    padding: 50px 0px;
    overflow: auto;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    .header {
      width: 50%;
      min-width: 500px;
      font-weight: 600;
      font-size: 24px;
      line-height: 150%;
      color: #18181b;
      margin-bottom: 50px;
    }
  }
}
.question-wrapper {
  width: 50%;
  min-width: 500px;
  .question {
    margin-bottom: 2rem;
    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #111928;
      margin-bottom: 8px;
    }
  }
  .statement {
    background: #f3f4f6;
    border-radius: 6px;
    width: 100%;
    padding: 20px;
    .instruction {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #111928;
      margin-bottom: 1rem;
    }
    .description {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #111928;
    }
  }
}
