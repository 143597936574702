.my-study {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .nav-bar {
    display: flex;
    align-items: center;
    width: 100%;
    height: 64px;
    background: #262626;
    padding: 1rem;
  }
  .my-study-content {
    flex: 1;
    min-height: 1px;
    display: flex;
    flex-direction: column;
    .search-nav {
      display: flex;
      align-items: center;
      padding: 1rem 40px;
      width: 100%;
      height: 91px;
      background: #ffffff;
      border-bottom: 1px solid #e5e7eb;
      .econsent-header {
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        color: #111928;
        margin-right: 45px;
      }
    }
    .study-content-wrapper {
      padding: 40px;
      flex: 1;
      min-height: 1px;
      overflow: auto;
      .study-header {
        font-weight: 600;
        font-size: 22px;
        line-height: 150%;
        color: #111928;
        margin-bottom: 20px;
      }
      .study-wrapper {
        margin-bottom: 30px;
        .study-card {
          cursor: pointer;
          display: flex;
          padding: 0.5rem 2rem;
          align-items: center;
          height: 84px;
          margin-bottom: 1rem;
          background: #ffffff;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06),
            0px 1px 3px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          .live {
            width: 15%;
            display: flex;
            align-items: center;
            .dot {
              width: 13px;
              height: 13px;
              border-radius: 50%;
              background: #33bc28;
              display: block;
            }
            .type {
              font-weight: 600;
              font-size: 16px;
              line-height: 150%;
              color: #33bc28;
            }
          }
          .not-live {
            width: 15%;
            display: flex;
            align-items: center;
            .dot {
              width: 13px;
              height: 13px;
              border-radius: 50%;
              background: #e4e4e7;
              display: block;
            }
            .type {
              font-weight: 600;
              font-size: 16px;
              line-height: 150%;
              color: #111928;
            }
          }
          .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            color: #111928;
          }
          .contact {
            .pretext {
              font-weight: 600;
            }
            margin-left: auto;
            font-size: 16px;
            line-height: 150%;
            color: #111928;
          }
        }
        .appointment-card {
          display: flex;
          padding: 0.5rem 2rem;
          align-items: center;
          height: 84px;
          margin-bottom: 1rem;
          background: #ffffff;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06),
            0px 1px 3px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          .appt-name {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #111928;
            margin-left: 12px;
          }
          .appt-time {
            margin-left: auto;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #6b7280;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .my-study {
    .my-study-content {
      .study-content-wrapper {
        padding: 1rem;
        .study-wrapper {
          .study-card {
            padding: 1rem;
            flex-direction: column;
            align-items: flex-start;
            height: auto;
            min-height: 84px;
            .live,
            .not-live {
              width: 100%;
              margin-bottom: 0.5rem;
            }
            .title {
              margin-bottom: 0.5rem;
            }
          }
        }
      }
    }
  }
}
