.econsent-signing {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #fafafa;
  .econsent-signing-nav {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0px 60px;
    .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #484848;
    }
  }
  .econsent-signing-content {
    flex: 1;
    min-height: 0px;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    padding: 60px;
    .econsent-signing-content-left {
      background: #ffffff;
      border-radius: 6px;
      width: 70%;
      height: 100%;
      overflow: auto;
      padding: 40px;
      margin-right: 30px;
      .title {
        font-weight: 400;
        font-size: 22px;
        line-height: 27px;
        text-transform: uppercase;
        color: #111928;
        margin-bottom: 20px;
      }
      .title-research {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #52575c;
        margin-bottom: 10px;
      }
      .study-name {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #111928;
        margin-bottom: 30px;
      }
      .study-member {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #52575c;
        margin-bottom: 10px;
      }
      .study-member-name {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #111928;
        margin-bottom: 20px;
      }
      .contract-wrapper {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        .contract-header {
          font-weight: 600;
          font-size: 14px;
          line-height: 150%;
          color: #111928;
        }
        .contract-desc {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #52575c;
          margin-bottom: 30px;
        }
      }
      .signing-checkbox {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #27272a;
        &.disable {
          opacity: 0.4;
        }
      }
      .signature-wrapper {
        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        &.disable {
          opacity: 0.4;
        }
        .signature-title {
          font-weight: 400;
          font-size: 22px;
          line-height: 27px;
          color: #111928;
          margin-bottom: 1rem;
        }
        .signature-desc {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #52575c;
          margin-bottom: 20px;
        }
        .label-text {
          margin-top: 30px;
          margin-bottom: 10px;
          font-weight: 600;
          font-size: 14px;
          line-height: 100%;
          color: #212121;
        }
        .label-text-1 {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #52575c;
        }
        .sig-preview {
          border-radius: 6px;
          height: 160px;
          width: 70%;
        }
        .canvas-element {
          border: 1px solid #dcdce0;
          border-radius: 6px;
          height: 160px;
          width: 70%;
        }
      }
    }
    .econsent-signing-content-right {
      flex: 1;
      min-width: 1px;
      position: relative;
      height: 100%;
      .button-container {
        position: fixed;
        top: 20px;
        right: 60px;
        display: flex;
        .action-btn {
          border: 1px solid #52525b;
          height: 41px;
          border-radius: 6px;
          .onText {
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            color: #52525b;
          }
          &.off {
            border: 1px solid #e02424;
            .offText {
              font-weight: 600;
              font-size: 14px;
              line-height: 150%;
              color: #e02424;
            }
          }
        }
        .end-btn {
          background: #e02424;
          border: 1px solid #e02424;
          height: 41px;
          border-radius: 6px;
          .endCall {
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            color: #ffffff;
          }
        }
      }

      .ot-message {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #52575c;
        padding-top: 25px;
        width: 100%;
        text-align: center;
      }
      .ot-publisher {
        overflow: hidden;
        position: absolute;
        top: 10px;
        right: 20px;
        border: 1.6px solid #ffffff;
        border-radius: 4px;
        .OT_publisher {
          width: 126px !important;
          height: 156px !important;
          border-radius: 4px;
        }
      }
      .OT_subscriber {
        width: 100% !important;
        height: 400px !important;
        border-radius: 4px;
      }
    }
  }
}
