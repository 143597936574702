.econsent-layout {
  height: 100vh;
  width: 100vw;
  display: flex;
  .econsent-sider {
    width: 60px;
    height: 100%;
    background: #262626;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 8px;
    .sider-divider {
      background: #ffffff;
      opacity: 0.1;
    }
    .item {
      margin-bottom: 8px;
    }
    .item.active {
      .econsent-svg {
        .econsent-svg-rect {
          fill: #515151;
        }
        .econsent-svg-path {
          fill: #ffffff;
        }
      }
    }
  }
  .econsent-content {
    flex: 1;
    min-width: 0px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    position: relative;
    .econsent-content-nav {
      height: 64px;
      display: flex;
      width: 100%;
      align-items: center;
      border-bottom: 1px solid #e5e7eb;
      padding: 0px 20px;
      .study-name {
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        color: #000000;
      }
    }
    .econsent-content-wrapper {
      flex: 1;
      min-height: 0px;
      overflow: auto;
    }
  }
  .study-tab {
    height: 100%;
    .ant-tabs-nav {
      height: 80px;
      background-color: #ffffff;
      margin-bottom: 0px;
      padding: 0px 20px;
      .ant-tabs-tab {
        .ant-tabs-tab-btn {
          font-weight: 600;
          font-size: 18px;
          line-height: 150%;
          color: #111928;
        }
      }
      .ant-tabs-tab.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: #1c64f2;
        }
      }
      .ant-tabs-ink-bar {
        background-color: #1c64f2;
      }
    }
    .ant-tabs-content-holder {
      overflow: auto;
      .screening-question-wrapper {
        display: flex;
        flex-wrap: wrap;
        padding: 20px;
        gap: 25px;
        .no-questions-card {
          width: 100%;
          text-align: center;
          margin-top: 150px;
          padding: 16px;
          font-weight: 500;
          font-size: 28px;
          line-height: 150%;
          color: rgba(33, 33, 33, 0.6);
        }
        .question-card {
          background: #ffffff;
          border: 1px solid rgba(33, 33, 33, 0.13);
          border-radius: 6px;
          width: calc(20% - 25px);
          .q-image {
            width: 100%;
            height: 161px;
            object-fit: cover;
          }
          .title {
            padding: 16px;
            font-weight: 500;
            font-size: 14px;
            line-height: 140%;
            color: #212121;
          }
          .time {
            font-weight: 400;
            padding: 16px;
            font-size: 12px;
            line-height: 100%;
            color: rgba(33, 33, 33, 0.6);
          }
          .details {
            padding: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
    }
  }
  .econsent-question-builder {
    height: 100%;
    position: relative;
    .question-header {
      top: 64px;
      z-index: 2;
      padding: 0px 30px;
      width: calc(100% - 60px);
      position: fixed;
      display: flex;
      align-items: center;
      background: #ffffff;
      height: 90px;
      border-bottom: 1px solid #e5e7eb;
    }
  }
  .econsent-header {
    height: 90px;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    border-bottom: 1px solid #e5e7eb;
    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
      color: #111928;
    }
  }
  .econsent-consent-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .econsent-consent-wrapper {
      flex: 1;
      min-height: 1px;
      overflow: auto;
      padding: 20px;
    }
  }
  .status-tag {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    padding: 7px 11px;
    border-radius: 8px;
    text-transform: capitalize;
    &.published {
      background: rgba(51, 188, 40, 0.1);
      color: #33bc28;
    }
    &.archived {
      color: #4b5563;
      background: #f3f4f6;
    }
    &.draft {
      color: #ff8a4c;
      background: #feecdc;
    }
  }
  .econsent-consent-builder {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .econsent-build-wrapper {
      flex: 1;
      min-height: 1px;
      overflow: hidden;
      .consent-tab {
        height: 100%;
        .ant-tabs-nav {
          height: 60px;
          background-color: #ffffff;
          margin-bottom: 0px;
          padding: 0px 20px;
          .ant-tabs-tab {
            .ant-tabs-tab-btn {
              font-weight: 500;
              font-size: 14px;
              line-height: 150%;
              color: #6b7280;
            }
          }
          .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: #262626;
            }
          }
        }
        .ant-tabs-content-holder {
          height: calc(100% - 60px);
          overflow: auto;
        }
      }
      .consent-settings {
        width: 50%;
        min-width: 600px;
        padding: 20px;
      }
      .consent-content-builder {
        width: 60%;
        min-width: 600px;
        padding: 20px;
        .text-wrapper {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding-bottom: 40px;
          margin-bottom: 40px;
          .quill-container {
            .ql-editor {
              min-height: 350px;
            }
          }
        }
        .checkbox-wrapper {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding-bottom: 40px;
          margin-bottom: 40px;
          .text {
            font-weight: 600;
            font-size: 20px;
            line-height: 150%;
            color: #111928;
            margin-bottom: 10px;
          }
          .sub-text {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #212121;
            margin-bottom: 30px;
          }
          .label-text {
            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            color: #212121;
          }
          .quill-container {
            .ql-editor {
              min-height: 80px;
            }
          }
        }
        .statement-wrapper {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding-bottom: 40px;
          margin-bottom: 40px;
          .text {
            font-weight: 600;
            font-size: 20px;
            line-height: 150%;
            color: #111928;
            margin-bottom: 20px;
          }
          .sub-text {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #212121;
            margin-bottom: 20px;
          }
          .sub-text-1 {
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            color: #111928;
            margin-bottom: 10px;
          }
          .quill-container {
            .ql-editor {
              min-height: 80px;
            }
          }
        }
        .member-signature-wrapper {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding-bottom: 40px;
          margin-bottom: 40px;
          .text {
            font-weight: 600;
            font-size: 20px;
            line-height: 150%;
            color: #111928;
            margin-bottom: 20px;
          }
          .sub-text {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #212121;
            margin-bottom: 20px;
          }
          .member-item {
            border: 1px solid #e5e7eb;
            border-radius: 16px;
            margin-bottom: 16px;
            .edit-content {
              padding: 20px;
            }
            .header {
              height: 56px;
              display: flex;
              align-items: center;
              padding: 0px 20px;
              border-bottom: 1px solid #e5e7eb;
              .title {
                font-weight: 600;
                font-size: 18px;
                line-height: 150%;
                color: #111928;
              }
            }
            .label-text {
              font-weight: 600;
              font-size: 16px;
              line-height: 150%;
              color: #111928;
              margin-bottom: 1rem;
            }
            .label-text-1 {
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              color: #212121;
              margin-bottom: 1rem;
            }
          }
        }
      }
    }
  }
  .subject-container {
    .stage-tag {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      border-radius: 8px;
      padding: 0px 10px;
      &.pending {
        color: #ff8a4c;
        background: #feecdc;
      }
      &.pass {
        color: #33bc28;
        background: rgba(51, 188, 40, 0.1);
      }
      &.fail {
        color: #f05252;
        background: rgba(240, 82, 82, 0.1);
      }
    }
    .consent-tag {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      border-radius: 8px;
      padding: 0px 10px;
      &.success {
        color: #33bc28;
        background: rgba(51, 188, 40, 0.1);
      }
      &.pending {
        color: #ff8a4c;
        background: #feecdc;
      }
    }
    .card-wrapper {
      display: flex;
      margin-bottom: 40px;
      gap: 20px;
      .subject-card {
        flex: 1;
        min-width: 1px;
        background: #ffffff;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06),
          0px 1px 3px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        padding: 20px;
        .header {
          font-weight: 600;
          font-size: 18px;
          line-height: 150%;
          color: #111928;
        }
        .text {
          font-weight: 600;
          font-size: 30px;
          line-height: 150%;
        }
      }
    }
    .action-btns {
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .subject-detail {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .subject-detail-wrapper {
      flex: 1;
      min-height: 1px;
      overflow: hidden;
      .subject-tab {
        height: 100%;
        .ant-tabs-nav {
          height: 60px;
          background-color: #ffffff;
          margin-bottom: 0px;
          padding: 0px 20px;
          .ant-tabs-tab {
            .ant-tabs-tab-btn {
              font-weight: 500;
              font-size: 14px;
              line-height: 150%;
              color: #6b7280;
            }
          }
          .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: #262626;
            }
          }
        }
        .ant-tabs-content-holder {
          background-color: #f5f5f5;
          height: calc(100% - 60px);
          overflow: auto;
          padding: 20px;
          .consent-tag {
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            border-radius: 8px;
            padding: 0px 10px;
            &.pending {
              color: #ff8a4c;
              background: #feecdc;
            }
            &.pass {
              color: #33bc28;
              background: rgba(51, 188, 40, 0.1);
            }
          }
          .profile-card-container {
            width: 100%;
            display: flex;
            margin-bottom: 20px;
            gap: 20px;
            .profile-card {
              flex: 1;
              min-width: 1px;
              padding: 20px;
              background: #ffffff;
              border-radius: 8px;
              .header {
                font-weight: 600;
                font-size: 18px;
                line-height: 150%;
                color: #111928;
                padding-bottom: 10px;
                margin-bottom: 20px;
                border-bottom: 1px solid #e5e7eb;
              }
              .value-wrapper {
                display: flex;
                flex-wrap: wrap;
                .key-value-container {
                  width: 25%;
                  margin-bottom: 20px;
                }
                .key {
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 150%;
                  color: #111928;
                  margin-bottom: 10px;
                }
                .value {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 150%;
                  color: #111928;
                }
              }
            }
            .profile-card:first-child {
              min-width: 60%;
              max-width: 60%;
            }
          }
        }
      }
    }
  }
}
