//Update Study
.us {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  & > p {
    margin: 0;
  }

  // &__header {
  //   padding: 1rem;
  //   border-bottom: 1px solid #E5E7EB;
  //   font-size: 1.875rem;
  //   font-weight: 700;
  // }

  &__tabs {
    flex: 1;
    min-height: 1px;
    overflow: hidden;
    .update-study-tab {
      height: 100%;
      .ant-tabs-nav {
        height: 60px;
        background-color: #ffffff;
        margin-bottom: 0px;
        padding: 0px 20px;
        .ant-tabs-tab {
          .ant-tabs-tab-btn {
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
            color: #6b7280;
          }
        }
        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: #262626;
          }
        }
      }
      .ant-tabs-content-holder {
        height: calc(100% - 60px);
        overflow: auto;
        padding: 20px;
      }
    }
  }
}

//Update Study General ==============================================//
.usgen {
  width: 100%;

  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 3vh;
  }

  &__text-title {
    font-weight: 500;
  }

  &__text {
    font-weight: 500;
    color: #71717a;
    width: 60%;
  }

  &__logo-select {
    width: 100%;
    @include flex(row, center, flex-start);
    .file-text {
      font-style: italic;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #71717a;
    }
  }

  &__status,
  &__name {
    width: 100%;
    @include flex(column, flex-start, flex-start);
  }

  &__status,
  &__name > label {
    font-weight: 500;
  }

  .ant-select {
    width: 60%;
    height: 44px;
    .ant-select-selector {
      border-radius: 1rem;
    }
  }

  textarea {
    width: 60%;
  }

  // button {
  //   background-color: #262626;
  //   color: #e5e7eb;
  //   border-radius: 0.75rem;
  //   margin-bottom: 3vh;
  //   height: 42px;
  // }
}

//Update Study Configuration ========================================//
.uscon {
  width: 100%;

  &__title {
    font-size: 1.25rem;
    font-weight: 600;
  }

  &__card {
    box-shadow: 0px 1px 6px #0000001a;
    border-radius: 0.5rem;
    padding: 20px;
    width: 60%;
    margin-bottom: 20px;

    &__title {
      padding-bottom: 1rem;
      border-bottom: 1px solid #e5e7eb;
      font-size: 1.125rem;
      font-weight: 600;
      color: #111928;
    }

    &__subtitle {
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
      color: #18181b;
    }

    &__switch {
      @include flex(row, center, none);
      margin-bottom: 30px;

      & > p {
        margin: 0;
        margin-left: 20px;
      }
    }

    &__button {
      height: 42px;
      border-radius: 0.75rem;
    }
  }
}

.uscon-switch {
  .ant-switch-checked {
    background-color: #33bc28;
  }
}

//Update Study Personal Details =====================================//
.usdet {
  width: 100%;

  &__title {
    font-size: 1.125rem;
    font-weight: 600;
    width: 60%;
  }

  &__text {
    font-weight: 500;
    color: #71717a;
  }

  & > .ant-checkbox-wrapper {
    margin: 1vh 0;
  }
}
