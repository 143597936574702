.sd {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  p {
    margin: 0;
  }

  // &__header {
  //   height: 10vh;
  //   width: 100%;
  //   padding: 1rem;
  //   border-bottom: 1px solid #E5E7EB;
  //   font-size: 1.125rem;
  //   font-weight: 600;
  // }

  &__wrapper {
    flex: 1;
    min-height: 1px;
    overflow: auto;
  }
  &__details {
    width: 100%;
    padding: 1rem;
    // margin-bottom: 5vh;

    &--title {
      margin: 2vh 0;
      font-size: 1.125rem;
      font-weight: 700;
      @include flex(row, center, none);
    }

    //Grid
    &--body {
      width: 35%;
      display: grid;
      grid-template-columns: auto auto;
      row-gap: 2vh;

      &--title {
        width: 100%;
        font-weight: 600;
      }
    }

    &--btn {
      margin-top: 60px;
      @include flex(row, center, flex-end);

      // & > button {
      //   background-color: #262626;
      //   color: #fff;
      //   border-radius: 0.75rem;
      // }
    }
  }

  &__table {
    width: 100%;
    padding: 1rem;

    &--style {
      .ant-table-thead > tr > th {
        background-color: #f3f4f6;
      }
    }
  }
}

.sd__modal-select {
  .ant-select ant-select-lg ant-select-single ant-select-show-arrow {
    border: 1px solid #e5e7eb;
    border-radius: 1rem;
  }
}
